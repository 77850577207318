var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "order-detail" },
    [
      _c("detail", {
        attrs: {
          billType: _vm.billType,
          uuid: _vm.uuid,
          isEnableSale: true,
          tableSpecialCount: 1,
        },
        scopedSlots: _vm._u([
          {
            key: "tableSpecial",
            fn: function () {
              return [
                _c("el-table-column", {
                  attrs: {
                    label: "价格方案",
                    align: "center",
                    prop: "priceCaseName",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }