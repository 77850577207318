<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import { allStoreList } from '@/api/system/store' //仓库
import { listCategory } from '@/api/goods/category' //商品类别
import { getAllGoodsList } from '@/api/goods/goods' // 商品列表
import { saleUnsalableAPI } from '@/api/sale/analyse/unsoldGoods' //销售滞销

export default {
  name: 'unsaleGoods',
  components: { TablePage },
  data () {
    return {
      options: {
        mutiSelect: false, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        uuid:'f02222de-71fb-ae81-222d-44944eefcabd',
        getListApi: saleUnsalableAPI,
        body: {},
        listNo: true, // 序号
        title: '销售滞销表',
        check: [], // 选中数据
        rowKey: 'goodsNo',
        search: [
          {
            type: 'input',
            tip: '商品编码/条码/商品名称',
            model: '',
            filter: 'query'
          },
          this.$select({
            key: "listStore",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          this.$select({
            key: "listCategory",
            option: { seniorSearch: true, option: { multiple: true } },
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        columns: [
          {
            prop: 'categoryName',
            label: '商品分类',
            type: 'link',
            click: 'routerLink',
            minWidth: 180
          },
          {
            prop: 'goodsNo',
            label: '商品编码',
            minWidth: 155
          },
          {
            prop: 'barcode',
            label: '条码',
            minWidth: 120
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 100
          },
          {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 140
          },
          {
            prop: 'noSalesDays',
            label: '无销售天数',
            minWidth: 160
          },
          {
            prop: 'auditTime',
            label: '上次销售日期',
            minWidth: 160
          },
          {
            prop: 'unitName',
            label: '基本单位',
            minWidth: 120
          },
          {
            prop: 'finalQty',
            label: '库存',
            minWidth: 160
          },
          {
            prop: 'storeNo',
            label: '仓库编号',
            minWidth: 160
          },
          {
            prop: 'storeName',
            label: '仓库名称',
            minWidth: 160
          }
        ],
        list: []
      }
    }
  },
  methods: {
    async handleEvent (type, row) {
      const selectData = this.options.check
      let url = '/api/system/purchase/bill/updateBillStatus'
      let billType = '110101'
      switch (type) {
        case 'routerLink':
          {
            const billId = row.billId
            this.$router.push({
              name: 'PurchaseOrderDetail',
              query: {
                billId: billId,
                type: 'Update'
              }
            })
          }
          break
        case 'add':
          {
            this.$router.push({
              name: 'PurchaseOrderDetail',
              query: {
                type: 'Add'
              }
            })
          }
          break
        case 'audit':
          {
            if (!selectData.length) return
            this.handleBillStatus('审核', selectData, url, billType)
          }
          break
        case 'redoAudit':
          {
            if (!selectData.length) return
            this.handleBillStatus('反审核', selectData, url, billType)
          }
          break
        case 'del':
          {
            if (!selectData.length) return
            this.handleBillStatus('删除', selectData, url, billType)
          }
          break
        case 'stop':
          {
            if (!selectData.length) return
            this.handleBillStatus('关闭', selectData, url, billType)
          }
          break
        default:
          break
      }
    },
    seniorSearch () {},
    handleBillStatus (command, selectData, url, BillType, type) {
      let billData = [] //过滤的数据
      let name = '' //要执行的状态名字
      let tag = '' //提示的状态
      let billStatus = '' //要执行的状态数字
      if (command === '反审核') {
        // billData = selectData.filter(item => (item.billStatus === '2'))
        name = '反审核'
        tag = '已审核'
        billStatus = '0'
      } else {
        // billData = selectData.filter(item => (item.billStatus === '0'))
        if (command === '审核') {
          name = '审核'
          tag = '未审核'
          billStatus = '2'
        } else if (command === '删除') {
          name = '删除'
          tag = '未审核'
          billStatus = '3'
        } else if (command === '关闭') {
          name = '关闭'
          tag = '未审核'
          billStatus = '4'
        }
      }
      const billId = selectData.map(i => i.billId)
      const billNo = selectData.map(i => i.billNo)
      const $this = this
      this.$modal
        .confirm(`是否确认${name}单据编号为"` + billNo + '"的单据项?')
        .then(function () {
          return updateInstoreStatus(
            [...billId],
            billStatus,
            url,
            BillType
          ).then(() => {
            $this.$nextTick(() => {
              $this.options.check = []
            })
            $this.$modal.msgSuccess(`${name}成功`)
            setTimeout(() => {
              $this.$refs.tablePage.getList()
            }, 500)
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped></style>
