<template>
  <!-- 销售退货单 -->
  <div class="back-detail">
    <detail :billType="billType" :uuid="uuid" :tableBillSourceCout="1" :tableSpecialCount="1">
      <template #tableBillSource>
        <el-table-column
          label="单据源"
          width="160"
          align="center"
          :formatter="id2Content"
          show-overflow-tooltip
        >
        </el-table-column>
      </template>
      <template #tableSpecial>
        <el-table-column label="可退数量" align="center" prop="importUnitQty">
        </el-table-column>
      </template>
    </detail>
  </div>
</template>

<script>
import detail from '@/views/components/bill/detail-layout.vue';
export default {
  name:'SaleBackDetail',
  components:{ detail },
  data () {
    return {
      billType:'120103',
      uuid:'18deac5d-03c6-acaf-1e2a-71cdccbaf69c'
    }
  },
  methods: {
    id2Content (row, column, cellValue, index) {
      if(row.sourceBillDetailId) {
        return '销售出库单'
      }
    }
  }
}
</script>
